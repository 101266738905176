import { login, logout, getInfo, getTaskResources } from "@/api/login/login";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { Message } from "element-ui";

const user = {
  state: {
    token: getToken(),
    nickname: "",
    avatar: "",
    roles: [],
    permissions: [],
    tokencounts: "",
    drawcounts: "",
    drawdate: "",
    tokendate: "",
    info: [],
    resource: {},
    signcount: "",
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NICKNAME: (state, nickname) => {
      state.nickname = nickname;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    SET_TOKENCOUNTS: (state, tokencounts) => {
      state.tokencounts = tokencounts;
    },
    SET_TOKENDATE: (state, tokendate) => {
      state.tokendate = tokendate;
    },
    SET_DRAWDATE: (state, drawdate) => {
      state.drawdate = drawdate;
    },
    SET_DRAWCOUNTS: (state, drawcounts) => {
      state.drawcounts = drawcounts;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    SET_RESOURCE: (state, resource) => {
      state.resource = resource;
    },
    SET_SIGNCOUNT: (state, signcount) => {
      state.signcount = signcount;
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim();
      const password = userInfo.password;
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((res) => {
            if (res.code == 500) {
              console.log(res);
              Message.error(res.msg);

              reject(res.msg);
            } else if (res.code == 200) {
              setToken(res.data.access_token);
              localStorage.setItem("setItem", false);

              commit("SET_TOKEN", res.data.access_token);
              resolve();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((res) => {
            // if (res.user.avatar === '') {
            //   res.user.avatar = require('@/assets/img/mycenter/默认头像.png')

            // }

            console.log(res.user);
            commit("SET_SIGNCOUNT", res.user.conSignCount);
            localStorage.setItem("signcount", res.user.conSignCount);
            localStorage.setItem("userId", res.user.userId);
            localStorage.setItem("info", JSON.stringify(res.user));
            localStorage.setItem("useRule", JSON.stringify(res.user.useRule));

            getTaskResources(res.user.userId)
              .then((re) => {
                console.log(re.data);

                commit("SET_RESOURCE", re.data);
                localStorage.setItem("resource", JSON.stringify(re.data));

                resolve(re);
              })
              .catch((error) => {
                reject(error);
              });
            // const user = res.user
            // const avatar = (user.avatar == "" || user.avatar == null) ? require("@/assets/images/profile.jpg") :  user.avatar;
            // if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            //   commit('SET_ROLES', res.roles)
            //   commit('SET_PERMISSIONS', res.permissions)
            // } else {
            //   commit('SET_ROLES', ['ROLE_DEFAULT'])
            // }

            commit("SET_INFO", res.user);
            commit("SET_AVATAR", res.user.avatar);

            commit("SET_NICKNAME", res.user.nickName);
            commit("SET_TOKENCOUNTS", res.user.tokenBalance);
            commit("SET_DRAWDATE", res.user.drawExpire);

            commit("SET_TOKENDATE", res.user.tokenExpire);
            commit("SET_DRAWCOUNTS", res.user.drawBalance);

            resolve(res);
          })
          .catch((error) => {
            console.error("GetInfo操作中的错误：", error);
            //    store.dispatch('LogOut').then(() => {
            //   Message.error(err)
            //   this.$router.push("/home");
            // })
            reject(error);
          });
      });
    },

    // 查询用户资源
    getTaskResources({ commit, state }) {
      const userId = state.userId;

      return new Promise((resolve, reject) => {
        getTaskResources(userId)
          .then((res) => {
            console.log(res.user);
            commit("SET_RESOURCE", res.data);

            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit("SET_TOKEN", "");
            commit("SET_ROLES", []);
            commit("SET_PERMISSIONS", []);
            commit("SET_AVATAR", "");
            commit("SET_INFO", "");
            commit("SET_DRAWCOUNTS", "");
            commit("SET_TOKENCOUNTS", "");
            localStorage.removeItem("info");
            localStorage.removeItem("signcount");

            localStorage.removeItem("useRule");

            localStorage.removeItem("userId");

            removeToken();
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "");
        removeToken();
        resolve();
      });
    },
  },
};

export default user;
