import Vue from "vue"
import request from '@/utils/request'

// export function login(data){
//     return Vue.prototype.$http.post(`/ruoyi-auth/auth/login`,data,)
// }
//登录
export function login(data) {
    return request({
      url: '/auth/login',
      method: 'post',
      data: data
    })
  }
  //获取用户信息/ruoyi-system/user/updateUserInfo
  export function getInfo() {
    return request({
      url: '/ruoyi-system/user/getInfo',
      method: 'get'
    })
  }
    // 忘记密码发送手机验证码
export function getResetSendCode(data) {
  return request({
    url: "/auth/resetSendCode",
    method: "get",
    params: data,
  });
}

  // 注册发送手机验证码
export function getRegisterCode(data) {
  return request({
    url: "/auth/sendCode",
    method: "get",
    params: data,
  });
}
// 忘记密码提交
export function resetPasswordInfo(data) {
  return request({
    url: "/auth/resetPassword",
 
    method: "post",
    data: data,
  });
}

// 注册提交
export function registerUser(data) {
  return request({
    url: "/auth/register",
 
    method: "post",
    data: data,
  });
}

// 退出方法
export function logout() {
  return request({
    url: '/auth/logout',
    method: 'delete'
  })
}
  
// 用户头像上传
export function uploadAvatar(data) {
  return request({
    url: '/ruoyi-system/user/profile/headSculpture',
    method: 'post',
    data: data,
    headers: { "Content-Type": "multipart/form-data" }
  })
}

//获取用户签到等资源


export function getTaskResources(userId) {
  return request({
    url: '/ruoyi-system/user/taskResources/'+userId,
    method: 'get',
  })
}