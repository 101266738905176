const getters = {
    avatar: state => state.user.avatar,
    nickname: state => state.user.nickname,
    tokencounts:state => state.user.tokencounts,
    drawcounts:state =>state.user.drawcounts,
    drawdate: state =>state.user.drawdate,
    tokendate: state =>state.user.drawdate,
    resource: state => state.user.resource,
    info : state => state.user.info,
    signcount: state =>state.user.signcount
  }
  export default getters
  